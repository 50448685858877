import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { partial } from '../../../util/translation/translation';
import renderTextAreaField from '../../../shared/components/custom/TextArea';
import Api from '../../../util/api';

const s = partial('shared');
const CommentModal = ({
  selectedParticipantForComment,
  getPaticipants,
  handleCloseModal,
  initialize }) => {
  useEffect(() => {
    initialize({
      additionalComment: selectedParticipantForComment.additionalNotes,
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const additionalComment = event.target[0].value;
      try {
          await Api.events.addAdditionalNotes(
            { additionalComment }, selectedParticipantForComment.id,
          );

        toast.success('Notes added successfully');
        handleCloseModal();
        getPaticipants();
      } catch (err) {
        toast.error('Failed to add notes');
      }
  };


  return (

    <form className="form" onSubmit={handleSubmit}>
      <div className="form__form-group">
        <span className="form-header bold">Add an additional note for {selectedParticipantForComment?.firstname}</span>
        <Field
          name="additionalComment"
          component={renderTextAreaField}
          type="text"
          placeholder="Add Notes"
        />
      </div>
      <Row>
        <Col>
          <Button color="primary" type="submit">
            {selectedParticipantForComment?.additionalNotes ? `${s('save')}` : `${s('add')}`}
          </Button>
        </Col>
      </Row>
    </form>
  );
};


const reduxF = reduxForm({
  form: 'CommentModal',
});
export default reduxF(CommentModal);
