import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Col, Container, Row, CardBody, Card, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Api from '../../util/api';
import EventsForm from './components/EventsForm';
import Loading from '../../shared/components/custom/Loading';
import NavTab from '../../shared/components/custom/NavTab';
import t, { partial } from '../../util/translation/translation';
import { isValidUrl } from '../../util/functions';
import TabHeader from '../../shared/components/scheduledMessages/TabHeader';

const EventsClonePage = ({ eventForm = {}, location }) => {
  const [tab, setTab] = useState('');
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [eventLevels, setEventLevels] = useState([]);
  const [eventGroups, setEventGroups] = useState([]);

  const { eventID } = useParams();
  const history = useHistory();
  const p = partial('EventsFormPage');

  const getEventCategoriesMemo = useCallback(() => Api.events.getEventCategories(), []);
  const getLevelsMemo = useCallback(() => Api.club.getLevels(), []);
  const getGroupsMemo = useCallback(() => Api.club.getGroups(), []);

  const getData = async () => {
    setLoading(true);
    try {
      const [resEventCategories, resEventLevels, resEventGroups] = await Promise.all([
        getEventCategoriesMemo(),
        getLevelsMemo(),
        getGroupsMemo(),
      ]);
      setEventCategories(resEventCategories.data.map((category) => {
        const { id, title, maxAttendees } = category;
        return {
          value: id,
          label: title,
          maximum: maxAttendees,
        };
      }));
      // setWholeEventCategories(resEventCategories.data);
      setEventLevels(resEventLevels.map((level) => {
        const { id, value } = level;
        return {
          value: id,
          label: value,
        };
      }));

      setEventGroups(resEventGroups.map((group) => {
        const { id, value } = group;
        return {
          value: id,
          label: value,
        };
      }));

      if (eventID) {
        if (location.state?.event) {
          setEvent({ ...location.state.event, imageUrl: null });
        } else {
          const resEvent = await Api.events.show(eventID);
          setEvent({ ...resEvent.data, imageUrl: null });
        }
      }
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [eventID]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const filterGroupsLevels = (selectedArray) => {
    if (selectedArray.length === 0) {
      return null;
    }
    const valueArray = selectedArray.map(({ value }) => value);
    const containsForAll = valueArray.some((item) => Array.isArray(item));
    const containsNotApplicable = valueArray.some((item) => !item);
    if (containsForAll) {
      return [];
    }
    if (containsNotApplicable) {
      return null;
    }
    return selectedArray.map((item) => item.label);
  };
  const onSubmit = async (values) => {
    const accessLevels = filterGroupsLevels(values?.eventLevels);
    const accessCategories = filterGroupsLevels(values?.eventGroups);
    const payload = {
      title: values.title,
      ...(isValidUrl(values.mapsUrl) && values.mapsUrl !== '' && {
        mapsUrl: values.mapsUrl,
      }),
      address: values.address,
      zip: values.zip,
      description: values.description,
      ...(!values.eventCategories?.value && {
        maxAttendees: Number(values.maxAttendees),
        maxAccountAttendees: Number(values.maxAccountAttendees) === 0 || null ?
          Number(values.maxAttendees) : Number(values.maxAccountAttendees),
      }),
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      registrationStart: values.registrationStart ? new Date(values.registrationStart).toISOString() : null,
      registrationEnd: values.registrationEnd ? new Date(values.registrationEnd).toISOString() : null,
      categoryId: values.eventCategories?.value,
      accessLevels,
      accessCategories,
      notify_user: values.notify_user,
      urlDescription: values.urlDescription,
      urlText: values.urlText,
      ...(isValidUrl(values.websiteUrl) && values.websiteUrl !== '' && {
        websiteUrl: values.websiteUrl,
      }),
      ...(selectedCountry !== null && {
        country: selectedCountry,
      }),
      ...(values.city !== '' && {
        city: values.city,
      }),
    };
    setLoading(true);
    try {
      const image = values.imageUrl || '';
      if (accessLevels === null && accessCategories === null) {
        toast.error(t('EventCategories.groupOrLevelMustBeSelected'));
        throw new Error(t('EventCategories.groupOrLevelMustBeSelected'));
      }
      const { data: resEvent } = await Api.events.create(payload);
      await image.promise?.('event', 'imageUrl', resEvent.id);
      toast.success(p('eventCreated'));
      history.replace('/events');
    } catch (err) {
      setLoading(false);
      toast.error(err);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{t('shared.create')} {t('shared.event')}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <NavTab
                tabs={[
                  t('shared.details'),
                { tab: <TabHeader
                  count={0}
                  heading={t('shared.communication')}
                  iconSize="22"
                  limit={9}
                />,
                disabled: true },
                { tab: t('shared.participants'), disabled: true },
                { tab: t('question.questions'), disabled: true },
              ]}
                activeTab={tab}
                setTab={setTab}
                inline
              />
              <TabContent activeTab={tab}>
                <TabPane tabId="1">
                  <EventsForm
                    eventForm={eventForm}
                    event={event}
                    onSubmit={onSubmit}
                    eventCategories={eventCategories}
                    eventLevels={eventLevels}
                    eventGroups={eventGroups}
                    setSelectedCountry={setSelectedCountry}
                    numberOfPendingMessagesPerEvent={0}
                    eventTiers={[]}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(connect(state => ({
  eventForm: getFormValues('events_form')(state),
}))(EventsClonePage));
