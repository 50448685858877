import React, { useState } from 'react';
import {
  Container,
  Col,
  Card,
  CardBody,
  TabContent,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getAuth } from 'firebase/auth';
import MessagesForm from './components/MessagesForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import LazyTabPane from '../../shared/components/custom/LazyTabPane';
import t, { partial } from '../../util/translation/translation';
import NavTab from '../../shared/components/custom/NavTab';
import useConfig from '../../util/useConfig';
import MessagesListPage from '../../shared/components/messageList/MessagesListPage';
import CommunicationApiPage from './CommunicationApiPage';


const p = partial('MessagesPage');
const s = partial('shared');

const MessagesPage = ({ me = undefined, smsForm = {} }) => {
  const history = useHistory();
  const config = useConfig();
  const [tab, setTab] = useState(history?.location?.state?.tab || 0);
  const [loading, setLoading] = useState(false);
  const [scheduleMessageID, setScheduleMessageID] = useState(null);
  const [clearContactPage, setClearContactPage] = useState(false);
  const [startDate, setStartDate] = useState();

  const handleSendMessage = async (payload) => {
    if (scheduleMessageID) {
      await Api.scheduleMessages.updateMessage(payload, scheduleMessageID);
     } else if (payload.scheduledDate) {
         await Api.scheduleMessages.sendMessage(payload);
      } else {
        await Api.userDevices.sendMessage(payload);
      }
  };
  const checkRecieversAndSendMessage = async (countUsers, values, onlyMe = '') => {
    const { externalUrlAddress, pushLabel } = values;
    const deeplinkUrl = values.mode?.value === '-1' ? null : values.mode.value;
    const navUrl = externalUrlAddress?.includes('http')
      ? externalUrlAddress
      : deeplinkUrl;
    const tierIds = smsForm?.tiers[0].value === null ? [] : smsForm?.tiers?.map((item) => (item.label === 'All' ? 'all' : item.value));
    const groupNames = smsForm?.groups[0].value === null ? [] : smsForm?.groups?.map((item) => (item.label === 'All' ? 'all groups' : item.label),
    );
    const levelNames = smsForm?.levels[0].value === null ? [] : smsForm?.levels?.map((item) => (item.label === 'All' ? 'all levels' : item.label));
    const segmentIds = smsForm?.segments[0].value === null ? [] : smsForm?.segments?.map((item) => (item.label === 'All' ? 'all segments' : item.value));

    const targetAudienceForPayload = smsForm.targetAudience === 'custom' && tierIds.length === 0 && groupNames.length === 0 && levelNames.length === 0 && segmentIds.length === 0 ? 'all' : smsForm.targetAudience;
    setLoading(true);
    let userCloudID = '';
    try {
      if (onlyMe) {
        const firebaseAuth = getAuth();
        userCloudID = firebaseAuth.currentUser.uid;
      }
      if (smsForm.media === 'sms') {
        const payload = { channels: [
          {
              channel: 'sms',
              templateId: 'sms',
          },
          ],
          dryRun: countUsers,
          recipients: onlyMe ? [{ userId: userCloudID }] : [],
          userGroups: !onlyMe && smsForm.targetAudience === 'all' ? ['all groups'] : [],
          tiers: !onlyMe ? tierIds : [],
          targetAudience: { // TODO: Check this one
            type: targetAudienceForPayload,
            tiers: tierIds,
            groups: groupNames,
            levels: levelNames,
            segments: segmentIds,
          },
          groups: groupNames,
          levels: levelNames,
          segments: segmentIds,
          placeholders:
          {
              Body: values.message,
              from: '',
          },
          senderInfo:
          {
              sender: `${config.id}`,
              type: onlyMe ? 'System' : 'Club',
          },
          scheduledDate: values.timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null,
          type: 'GENERAL',
        };
        await handleSendMessage(payload);
      } else {
        const payload = {
          channels: [
            {
              channel: 'push',
              templateId: 'push',
              pushLabel: pushLabel?.value || null,
            },
          ],
          dryRun: countUsers,
          recipients: [{ userId: userCloudID }] || [],
          userGroups: !onlyMe && smsForm.targetAudience === 'all' ? ['all groups'] : [],
          tiers: !onlyMe ? tierIds : [],
          targetAudience: {
            type: targetAudienceForPayload,
            tiers: tierIds,
            groups: groupNames,
            levels: levelNames,
            segments: segmentIds,
          },
          groups: !onlyMe ? groupNames : [],
          levels: !onlyMe ? levelNames : [],
          segments: !onlyMe ? segmentIds : [],
          placeholders:
              {
                body: values.message,
                title: values.heading,
                url: navUrl ?? values.type.value,
              },
          senderInfo:
              {
                sender: `${config.id}`,
                type: onlyMe ? 'System' : 'Club',
              },
          scheduledDate: values.timeToSend === 'SCHEDULED' ? moment.utc(startDate).format() : null,
          type: 'GENERAL',
        };
        await handleSendMessage(payload);
      }

      if (values.timeToSend === 'SCHEDULED' && scheduleMessageID) {
        toast.success(`${t('ScheduledMessagesPage.messageScheduled')}`);
      } else if (countUsers) {
        toast.success(`${p('messageWillBeSentToPart1/2')}
        ${''} ${p('messageToPart2/2')}`);
      } else {
        toast.success(`${p('messageSentToPart1/2')}
        ${''} ${p('messageToPart2/2')}`);
      }

      if (typeof onlyMe !== 'object' && !countUsers) {
        setClearContactPage(true);
      }
      setScheduleMessageID(null);
    } catch (e) {
      toast.error(p('messageSentError'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Loading loading={loading} />
      <Col>
        <Card>
          <CardBody style={{ padding: 0 }}>
            <NavTab
              tabs={[
                { tab: p('pushAndSms') },
                { tab: t('ScheduledMessagesPage.Messages') },
                { tab: s('settings') },
              ]}
              setTab={setTab}
              activeTab={String(tab)}
              size="sm"
            />
            <TabContent activeTab={tab}>
              <LazyTabPane activeTab={String(tab)} tabId="1">
                <MessagesForm
                  smsForm={smsForm}
                  checkRecieversAndSendMessage={checkRecieversAndSendMessage}
                  me={me}
                  setScheduleMessageID={setScheduleMessageID}
                  scheduleMessageID={scheduleMessageID}
                  clearContactPage={clearContactPage}
                  setClearContactPage={setClearContactPage}
                  startDate={startDate}
                  setStartDate={setStartDate}
                />
              </LazyTabPane>
              <LazyTabPane activeTab={String(tab)} tabId="2">
                <MessagesListPage/>
              </LazyTabPane>
              <LazyTabPane activeTab={String(tab)} tabId="3">
                <CommunicationApiPage />
              </LazyTabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Container>
  );
};

MessagesPage.propTypes = {
  me: PropTypes.shape({
    username: PropTypes.string,
  }),
  smsForm: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    group: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    })),
    media: PropTypes.string,
  }),
};

MessagesPage.defaultProps = {
  smsForm: {},
  me: undefined,
};

export default withRouter(connect(state => ({
  smsForm: getFormValues('send_messages_form')(state),
  me: state.admin.me,
}))(MessagesPage));
