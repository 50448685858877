import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getFormValues } from 'redux-form';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import UserForm from './components/UserForm';
import Api from '../../util/api';
import Loading from '../../shared/components/custom/Loading';
import PermissionForm from './components/PermissionForm';
import t, { partial } from '../../util/translation/translation';
import { capitalizeFirstLetter } from '../../util/functions';
import AssignTierForm from './components/AssignTierForm';
import DeleteModal from '../../shared/components/custom/DeleteModal';

import useConfig from '../../util/useConfig';

const p = partial('EditUserPage');
const s = partial('shared');


const EditUserPage = ({ formValues }) => {
  const config = useConfig();
  const { userID } = useParams();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [userAffiliations, setUserAffiliations] = useState([]);
  const [userCompanies, setUserCompanies] = useState([]);
  const [userServiceId, setUserServiceId] = useState(null);
  const [modalVis, setModalVis] = useState(null);
  const [removeAffiliationId, setRemoveAffiliationId] = useState(null);
  const [teamUserId, setTeamUserId] = useState(null);
  const [tiers, setTiers] = useState([]);

  const fetchUserCompanies = async (userCloudId) => {
    try {
      const { data: resEmployeeCompanies } = await Api.employees.getUserCompaniesForTeam(userCloudId);
      setUserCompanies(resEmployeeCompanies);
    } catch (e) {
      toast.error('Fetching user companies failed!');
    }
  };

  const getUserAffiliationAndTiers = async (userCloudId) => {
    const { data: tierData } = await Api.tiers.allTiers(config);
    setTiers(tierData);
    try {
      const { data: userAffiliationData } = await Api.tiers.getUserAffiliations(userCloudId, config);
      setUserAffiliations(userAffiliationData);
    } catch (e) {
      toast.error(p('errorFetchingUser'));
    }
  };
  const getUser = async () => {
    setLoading(true);
    try {
      const resUser = await Api.users.getUser(userID, config.cloudIdName);
      const availableRoles = await Api.users.getAvailableRoles();
      await getUserAffiliationAndTiers(resUser.userCloudId);
      await fetchUserCompanies(resUser.userCloudId);
      const teamUser = resUser.teams[0];
      const acceptedIds = teamUser.roles.map((role) => role.id);
      const mappedRoles = availableRoles.map((perm) => ({
        ...perm,
        toggled: acceptedIds.includes(perm.id),
      }));
      setPermissions(mappedRoles);
      setUserServiceId(resUser.id);
      setTeamUserId(resUser.teams[0].id);
      setUser({ ...resUser, companies: [] });
    } catch (err) {
      toast.error(err?.message ?? p('errorFetchingUser'));
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = async (e) => {
    const _permissions = [
      ...permissions,
    ];
    _permissions[e].toggled = !_permissions[e].toggled;
    setLoading(true);
    try {
      const { toggled, name } = _permissions[e];
      await Api.users.updateUserRoles(teamUserId, name, toggled);
      setPermissions(_permissions);
    } catch (err) {
      toast.error(err || p('errorGivingUserPolicies'));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      firstname: formValues.firstName,
      lastname: formValues.lastName,
      zip: formValues.postalCode,
      dateOfBirth: formValues.birthday ? moment(formValues.birthday).format('YYYY-MM-DD') : null,
      gender: formValues.gender,
      email: formValues.email,
    };
    setLoading(true);
    try {
      await Api.users.updateUserGraphQL(userServiceId, payload);
      toast.success(p('userWasUpdated'));
      history.replace('/users');
    } catch (err) {
      toast.error(err || s('somethingWentWrong'));
    } finally {
      setLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSubmitTier = async (values) => {
    const { dueDate, neverEnd, tier: { value: pickedTier } } = values;
    const { username, countryCode, userCloudId } = user;
    const payload = {
      dueDate,
      neverEnd,
      tierId: pickedTier === 'empty' ? null : pickedTier,
      phone: username,
      countryCode,
      cloudId: userCloudId,
    };
    setLoading(true);
    Api.tiers.assignUserToTier(payload, config).then(() => {
      toast.success(p('userWasUpdated'));
      history.replace('/users');
    }).catch((err) => {
      toast.error(err || s('somethingWentWrong'));
    }).finally(() => setLoading(false));
  };

  const deleteAffiliation = async () => {
    setLoading(true);
    try {
      const { userCloudId } = user;
      await Api.tiers.deleteUserAffiliation(removeAffiliationId, config);
      const { data: userAffiliationData } = await Api.tiers.getUserAffiliations(userCloudId, config);
      setUserAffiliations(userAffiliationData);
      setModalVis(false);
      setRemoveAffiliationId(null);
    } catch (e) {
      toast.error(e || p('failedToDeleteAffiliation'));
    } finally {
      setLoading(false);
    }
  };

  const handleModal = (isVis, affId) => {
    setModalVis(isVis);
    setRemoveAffiliationId(affId);
  };

  useEffect(() => {
    if (userID && config) {
      getUser();
    }
  }, [userID, config]);

  return (
    <Container>
      <DeleteModal type={t('UserTiers.affiliation')} visible={modalVis} handleModal={handleModal} modalAction={deleteAffiliation} />
      <Loading loading={loading} />
      <Row>
        <Col>
          <h3 className="page-title">{p('editUser')}</h3>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <div className="tabs tabs--justify tabs--bordered-top overflow-hidden">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    {s('user')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    {s('policies')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => { toggle('3'); }}
                  >
                    {capitalizeFirstLetter(t('UserTiers.tier'))}
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <UserForm
                    type="edit"
                    onSubmit={handleSubmit}
                    user={user}
                    userCompanies={userCompanies}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <PermissionForm
                    permissions={permissions}
                    handleToggle={handleToggle}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <AssignTierForm
                    availableTiers={tiers}
                    currentUserAffiliatons={userAffiliations}
                    onSubmit={handleSubmitTier}
                    handleModal={handleModal}
                  />
                </TabPane>
              </TabContent>
            </div>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default connect(state => ({
  formValues: getFormValues('UserForm')(state),
}))(EditUserPage);
