import { isValidUrl } from '../../../util/functions';
import { partial } from '../../../util/translation/translation';

const p = partial('validateErrors');
const validateEvents = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = p('nameFieldEmpty');
  }

  if (!values.description) {
    errors.description = p('descriptionFieldCantBeEmpty');
  }

  if (!values.maxAttendees) {
    errors.maxAttendees = p('participantsFieldCantBeEmpty');
  }

  if (Number(values.maxAccountAttendees) > Number(values.maxAttendees)) {
    errors.maxAccountAttendees = p('participantsFieldCantBeBiggerThanMaxParticipants');
  }

  if (values.mapsUrl && !isValidUrl(values.mapsUrl)) {
    errors.mapsUrl = p('mapsUrlFieldCantBeEmpty');
  }

  if (!values.startDate) {
    errors.startDate = p('dateFieldCantBeEmpty');
  }

  if (!values.endDate) {
    errors.endDate = p('dateFieldCantBeEmpty');
  }

  if (!values.registrationStart) {
    errors.registrationStart = p('dateFieldCantBeEmpty');
  }

  if (!values.registrationEnd) {
    errors.registrationEnd = p('dateFieldCantBeEmpty');
  }

  if (values.websiteUrl && !isValidUrl(values.websiteUrl)) {
    errors.websiteUrl = p('websiteUrlFieldCantBeEmpty');
  }

  // if (values?.eventLevels?.length === 0 && values?.eventGroups?.length === 0) {
  //   errors.eventLevels = p('youNeedToSelectOneLevelOrGroup');
  //   errors.eventGroups = p('youNeedToSelectOneLevelOrGroup');
  // }

  if (!values.doorsOpenAt) {
    errors.doorsOpenAt = p('doorsOpenAtCantBeEmpty');
  }

  if (!values.price) {
    errors.price = p('priceFieldCantBeEmpty');
  }

  // if (!values.imageUrl) {
  //   errors.imageUrl = p('picFieldCantBeEmpty');
  // }

  return errors;
};

export default validateEvents;
