import React from 'react';
import { Table } from 'reactstrap';
import PropTypes, { any } from 'prop-types';
import Checkbox from '../../../shared/components/custom/Checkbox';
import { partial } from '../../../util/translation/translation';

const s = partial('shared');
const r = partial('UserRoles');

const PermissionForm = ({ permissions, handleToggle }) => {
  const renderPermissions = () => {
    return permissions.map((permission, index) => (
      <tr key={`permission-${permission.id}`} className={permission.toggled ? 'listSelected transitionDuration-03' : 'transitionDuration-03'}>
        <td>
          <Checkbox
            index={index}
            handleToggle={handleToggle}
            toggled={permission.toggled}
            color="#ffffff"
            selectedColor="#ffffff"
            single
          />
        </td>
        <td>{r(permission.name)}</td>
        <td>{r(`${permission.name}Description`)}</td>
      </tr>
    ));
  };

  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th />
          <th>{s('role')}</th>
          <th>{s('description')}</th>
        </tr>
      </thead>
      <tbody>
        {renderPermissions()}
      </tbody>
    </Table>
  );
};

PermissionForm.propTypes = {
  permissions: PropTypes.arrayOf(any).isRequired,
  handleToggle: PropTypes.func.isRequired,
};

export default PermissionForm;
